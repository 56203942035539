import { CurrentUser } from '../src/contracts';
import api from './core';

export async function fetchCurrentUser() {
	const res = await api.get<CurrentUser>('/current-user', {
		withCredentials: true,
	});

	if (typeof res.data !== 'object') {
		throw new Error('API Error - ' + res.data);
	}

	return res.data;
}
