import { useEffect, useState } from 'react';

import { fetchCurrentUser } from '../api/user';
import { CurrentUser } from '../src/contracts';
import { MockLoggedInUser, MockLoggedOutUser } from '../src/mocks';
import { useDebugMode } from './useDebugMode';

export function useCurrentUser() {
	const { enabled: debugEnabled, query: debugQuery } = useDebugMode();
	const [isReady, setIsReady] = useState(false);
	const [user, setUser] = useState<CurrentUser | null>(null);

	useEffect(() => {
		if (debugEnabled) {
			setIsReady(true);
			setUser(
				debugQuery?.DEBUG_USER === 'loggedin'
					? MockLoggedInUser
					: MockLoggedOutUser,
			);

			return;
		}

		fetchCurrentUser()
			.then((currentUser) => {
				setUser(currentUser);
				setIsReady(currentUser.loggedIn);

				// Redirect the user to our login page
				if (!currentUser.loggedIn) {
					window.location.href = process.env.NEXT_PUBLIC_SIGN_IN_URL;
				}
			})
			.catch((error) => {
				throw error;
			});
	}, [debugEnabled]);

	return {
		isReady,
		user,
	};
}
