import { isProduction } from '@ucm-it/purrfect-utilities';

import { useCustomRouter } from './useCustomRouter';

interface DebugQueryHook {
	enabled: boolean;
	query: Record<string, string | undefined>;
	renderURL: (url: string) => URL;
}

export function useDebugMode(): DebugQueryHook {
	const { href, query } = useCustomRouter();

	if (isProduction()) {
		return {
			enabled: false,
			query,
			renderURL: (url: string) => new URL(url, href),
		};
	}

	return {
		enabled: query['DEBUG'] === 'true',
		query: query,
		renderURL: (rawUrl: string) => {
			const url = new URL(rawUrl, window.location.href);
			const searchParams = new URLSearchParams();

			Object.entries(query).forEach(([value, key]) => {
				if (key.startsWith('DEBUG')) {
					searchParams.set(key, value as string);
				}
			});

			url.search = searchParams.toString();

			return url;
		},
	};
}
