import { ApplicationTerms, CurrentUser, TermData } from './contracts';

const CurrentYear = new Date().getFullYear();
const NextYear = CurrentYear + 1;

export const MockLoggedInUser: CurrentUser = {
	username: 'DEBUG USER',
	loggedIn: true,
};

export const MockLoggedOutUser: CurrentUser = {
	username: 'ANONYMOUS',
	loggedIn: false,
};

const MockCampusAddress = Object.freeze({
	address1: '5200 N. Lake Rd.',
	address2: null,
	city: 'Merced',
	state: 'CA',
	zip: '95343',
});

enum SemesterTermCode {
	Spring = '01',
	Summer = '02',
	Fall = '03',
	Winter = '04',
}

const BaseMockApplications: TermData = Object.freeze({
	page: '',
	termcode: null,
	ucmnetid: 'dbg_rufus',
	paymentState: null,
	cancelledState: null,
	declinedSir: false,
	applicationNumber: null,
	sirStatus: null,
	studentId: null,
	sirDate: null,
	name: null,
	nickname: null,
	residency: null,
	isWueOffered: null,
	wueOfferUrl: null,
	undeterminedResidencyUrl: null,
	mailingAddress: MockCampusAddress,
	physicalAddress: MockCampusAddress,
	admissionTerm: null,
	degree: null,
	major: null,
	sirFee: null,
	admissionsLetterUrl: null,
	welcomeLinks: [
		{
			icon: 'globe',
			text: 'View Your Next Steps',
			url: 'https://admitted.ucmerced.edu/next-steps',
		},
		{
			icon: 'home',
			text: 'Learn About Housing',
			url: 'https://housing.ucmerced.edu',
		},
		{
			icon: 'map',
			text: 'Register for Upcoming Events',
			url: 'https://admitted.ucmerced.edu/events',
		},
		{
			icon: 'lock',
			text: 'Set up 2FA Authentication',
			url: 'https://it.ucmerced.edu/2FA-NewStudent',
		},
		{
			icon: 'globe',
			text: 'View Your Checklist',
			url: 'https://connect.ucmerced.edu/student/desktopchecklist/index',
		},
	],
	depositWaiverUrl: 'https://ucmerced.tfaforms.net/218099',
	payDepositUrl: null,
	payParameters: null,
	declineSurveyParameters: null,
	financialAidDetailsUrl: null,
	appealLateSirUrl: 'https://ucmerced.tfaforms.net/217919',
	appealDenialUrl: 'https://ucmerced.tfaforms.net/217806',
	appealDenialText: 'Admission Decision Appeal Form',
	admissionsEmail: 'admissions@ucmerced.edu',
	admissionsPhoneNumber: '(209) 228-7178',
	admissionsUrl: 'https://www.ucmerced.edu/admissions',
	studentPortalUrl: 'https://connect.ucmerced.edu/',
	acceptSurveyUrl: null,
	declineSurveyUrl: null,
	sirInstructionsUrl: 'https://admitted.ucmerced.edu/next-steps#Step_1',
	sirInstructionsText: 'Conditions of Admission',
	futureAdmissionTerm: null,
	waitListDueDate: null,
	paymentCompletedDuringSession: false,
});

export const MockNoApplications: ApplicationTerms = {
	terms: [],
};

const AcceptedApplication: TermData = {
	...BaseMockApplications,
	page: 'applicationAccepted',
	paymentState: 'required',
	name: 'Rufus Bobcat',
	nickname: 'Rufus',
	sirDate: new Date(),
	sirFee: '250',
	depositWaiverUrl: '#/deposit-waiver',
	admissionTerm: `Fall ${CurrentYear} (Pay: required)`,
	admissionsLetterUrl: '#/admissions-letter',
	degree: 'Undergraduate',
	major: 'Biology',
};

export const MockAcceptedApplication: ApplicationTerms = {
	terms: [
		{
			...AcceptedApplication,
			page: 'applicationAccepted',
			paymentState: 'required',
			admissionTerm: `Fall ${CurrentYear} (Pay: required)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}-r`,
			studentId: '12345678',
			residency: 'Resident',
			isWueOffered: true,
			payDepositUrl: '#/pay-deposit',
			payParameters: [
				{
					formData: 'sirFee',
					formValue: '250',
				},
			],
		},
		{
			...AcceptedApplication,
			page: 'applicationAccepted',
			paymentState: 'incomplete',
			admissionTerm: `Fall ${CurrentYear} (Pay: incomplete)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}-i`,
			studentId: '12345678',
			residency: 'Nonresident',
			isWueOffered: true,
			payDepositUrl: '#/pay-deposit',
			payParameters: [
				{
					formData: 'sirFee',
					formValue: '250',
				},
			],
		},
		{
			...AcceptedApplication,
			page: 'applicationAccepted',
			paymentState: 'deferred',
			residency: 'Undetermined',
			isWueOffered: true,
			admissionTerm: `Fall ${CurrentYear} (Pay: deferred)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}-d`,
		},
		{
			...BaseMockApplications,
			page: 'missedDeadline',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (Deadline: missed)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}`,
		},
	],
};

export const MockActiveStudent: ApplicationTerms = {
	terms: [
		{
			...BaseMockApplications,
			page: 'activeStudent',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear}`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}`,
		},
	],
};

const CanceledApplication: TermData = {
	...BaseMockApplications,
	page: 'applicationCanceled',
	name: 'Rufus Bobcat',
	nickname: 'Rufus',
	sirDate: new Date(),
	admissionsLetterUrl: '#/admissions-letter',
	declineSurveyUrl: '#',
	degree: 'Undergraduate',
	major: 'Biology',
};

export const MockCanceledApplication: ApplicationTerms = {
	terms: [
		{
			...CanceledApplication,
			page: 'applicationCanceled',
			cancelledState: 'cancelled',
			admissionTerm: `Fall ${CurrentYear} (Status: cancelled)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}-c`,
		},
		{
			...CanceledApplication,
			page: 'applicationCanceled',
			cancelledState: 'rescinded',
			admissionTerm: `Fall ${CurrentYear} (Status: rescinded)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}-r`,
		},
		{
			...CanceledApplication,
			page: 'applicationCanceled',
			cancelledState: 'declined',
			admissionTerm: `Fall ${CurrentYear} (Status: declined)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}-d`,
		},
	],
};

export const MockDelayedApplication: ApplicationTerms = {
	terms: [
		{
			...BaseMockApplications,
			page: 'applicationDelayed',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			admissionsLetterUrl: '#/admissions-letter',
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear}`,
			futureAdmissionTerm: `Spring ${NextYear} (Status: delayed)`,
			termcode: `${NextYear}${SemesterTermCode.Spring}`,
		},
		{
			...BaseMockApplications,
			page: 'acceptedSpringOffer',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			admissionsLetterUrl: '#/admissions-letter',
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (status: accepted)`,
			futureAdmissionTerm: `Spring ${NextYear}`,
			termcode: `${NextYear}${SemesterTermCode.Spring}`,
		},
		{
			...BaseMockApplications,
			page: 'declinedSpringOffer',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			admissionsLetterUrl: '#/admissions-letter',
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (status: declined)`,
			futureAdmissionTerm: `Spring ${NextYear}`,
			termcode: `${NextYear}${SemesterTermCode.Spring}`,
		},
	],
};

export const MockDeniedApplication: ApplicationTerms = {
	terms: [
		{
			...BaseMockApplications,
			page: 'applicationDenied',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (Status: denied)`,
			termcode: `${NextYear}${SemesterTermCode.Spring}`,
		},
	],
};

export const MockDualApplication: ApplicationTerms = {
	terms: [
		{
			...BaseMockApplications,
			page: 'applicationDelayed',
			paymentState: 'incomplete',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			mailingAddress: MockCampusAddress,
			physicalAddress: MockCampusAddress,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}`,
			admissionTerm: `Fall ${CurrentYear}`,
			admissionsLetterUrl: '#',
			degree: 'Undergraduate',
			major: 'Computer Science and Engineering',
			sirDate: new Date(),
			futureAdmissionTerm: `Spring ${NextYear}`,
		},
		{
			...BaseMockApplications,
			page: 'applicationDenied',
			paymentState: 'incomplete',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			mailingAddress: MockCampusAddress,
			physicalAddress: MockCampusAddress,
			termcode: `${NextYear}${SemesterTermCode.Spring}`,
			admissionTerm: `Spring ${NextYear}`,
			admissionsLetterUrl: '#',
			degree: 'Undergraduate',
			major: 'Computer Science and Engineering',
			sirDate: new Date(),
		},
	],
};

export const MockPendingApplication: ApplicationTerms = {
	terms: [
		{
			...BaseMockApplications,
			page: 'applicationPending',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (Status: pending)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}`,
			applicationNumber: 12345,
		},
	],
};

export const MockWaitlistApplication: ApplicationTerms = {
	terms: [
		{
			...BaseMockApplications,
			page: 'applicationWaitlisted',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (Status: waitlisted)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}`,
		},
		{
			...BaseMockApplications,
			page: 'acceptedWaitlist',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (Status: accepted waitlist)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}`,
		},
		{
			...BaseMockApplications,
			page: 'declinedWaitlist',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (Status: declined waitlist)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}`,
		},
		{
			...BaseMockApplications,
			page: 'missedWaitlistDeadline',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (Status: missed waitlist)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}`,
		},
	],
};

export const MockWelcome: ApplicationTerms = {
	terms: [
		{
			...BaseMockApplications,
			page: 'welcome',
			name: 'Rufus Bobcat',
			nickname: 'Rufus',
			sirDate: new Date(),
			degree: 'Undergraduate',
			major: 'Biology',
			admissionTerm: `Fall ${CurrentYear} (Go to Welcome)`,
			termcode: `${CurrentYear}${SemesterTermCode.Fall}`,
		},
	],
};
