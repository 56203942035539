import { Loader } from '@ucm-it/purrfect-components';
import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useCallback,
	useContext,
	useState,
} from 'react';

import { useCurrentUser } from '../hooks/useCurrentUser';
import { useLiveApplications } from '../hooks/useLiveApplications';
import { CurrentUser, TermData } from './contracts';
import { saveSelectedTerm } from './localStorage';

type StateSetter<T> = Dispatch<SetStateAction<T>>;

export interface CurrentSession {
	applications: TermData[];
	hasNoValidApplications: boolean;
	isReady: boolean;
	selectedApp: TermData | null;
	setSelectedApp: StateSetter<TermData | null>;
	user: CurrentUser;
}

export const CurrentSessionContext = createContext<CurrentSession>({
	applications: [],
	hasNoValidApplications: true,
	isReady: false,
	selectedApp: null,
	setSelectedApp: () => {},
	user: {
		loggedIn: false,
		username: '',
	},
});

interface Props {
	children: ReactNode;
}

export const CurrentSessionProvider = ({ children }: Props) => {
	const [selectedApp, setSelectedApp] = useState<TermData | null>(null);
	const handleSelectedAppChoice = useCallback((term: TermData) => {
		setSelectedApp(term);
		saveSelectedTerm(term);
	}, []);

	const { isReady: isUserReady, user } = useCurrentUser();
	const {
		applications,
		hasNoValidApplications,
		isReady: isApplicationsReady,
	} = useLiveApplications(handleSelectedAppChoice);

	let message = '';

	if (!isUserReady || !isApplicationsReady || !user?.loggedIn) {
		message = 'Checking user authentication...';

		if (!user?.loggedIn) {
			message = 'User not authenticated. Redirecting to login...';
		}

		if (!isApplicationsReady) {
			message = 'Loading applications...';
		}
	}

	return (
		<CurrentSessionContext.Provider
			value={{
				selectedApp,
				setSelectedApp: handleSelectedAppChoice,
				applications,
				hasNoValidApplications,
				isReady: isUserReady && isApplicationsReady,
				user,
			}}
		>
			{message.length > 0 ? (
				<Loader className="h-screen w-screen" message={message} />
			) : (
				children
			)}
		</CurrentSessionContext.Provider>
	);
};

export const useCurrentSession = () => {
	const context = useContext(CurrentSessionContext);

	if (!context) {
		throw new Error(
			'useCurrentSession must be used within a CurrentSessionProvider',
		);
	}

	return context;
};
