import { ReportingErrorBoundary } from '@ucm-it/purrfect-reporting';

import { CurrentSessionProvider } from '../src/contexts';

import '../styles/globals.css';

const App = ({ Component, pageProps }) => (
	<ReportingErrorBoundary>
		<CurrentSessionProvider>
			<Component {...pageProps} />
		</CurrentSessionProvider>
	</ReportingErrorBoundary>
);

export default App;
