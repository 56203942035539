/**
 * A simplified version of Next.js' `useRouter` hook that provides the current
 * URL and query parameters using `window.location`. This router is useful for
 * when you need to access the query parameters before the Next.js router has
 * finished hydrating.
 *
 * @see https://github.com/vercel/next.js/discussions/11484
 */
export function useCustomRouter() {
	// This is happening on the server-side or is being compiled
	if (typeof window === 'undefined') {
		return {
			href: '',
			isClientSide: false,
			pathname: '',
			query: {},
		};
	}

	const url = new URL(window.location.href);

	return {
		href: url.href,
		isClientSide: true,
		pathname: url.pathname.replace(/^\//, ''),
		query: Object.fromEntries(url.searchParams),
	};
}
